@import 'colors.scss';
@import "variables";

.kittens{
    z-index: 10;
    max-width: 1200px;
    margin: 1vh 5vw 5vh 5vw;

    @include mediaQ(small){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(medium){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(large){
        margin: 1vh auto 5vh auto;
    }

    &__title{
        margin: 3vh auto 5vh;
          width: 50%;
          font-size: 2rem;
          text-align: center;
          border-bottom: 2px solid $color-primary;
        }
        
        &__section{
            padding: 2vh 2vw;
            margin-top: 0;
        }
        &__important{
            text-decoration: underline;
            text-decoration-color: $color-primary;
        }

        &__text, &__list-item{
            font-size: 1rem;
            @include mediaQ(large){
                font-size: 1.4rem;
            }
            
        }

        &__text--title{
            font-size: 1.2rem;
            text-align: center;
            @include mediaQ(large){
                font-size: 1.8rem;
            }
        }

        &__text--middle{
            text-align: center;
        }

        &__text--invisible{
            opacity: 0;
        }

        &__text--visible{
            opacity: 1;
        }

        &__text--visible{
            display: inline-block;
        }

        &__text--big {
            font-size: 3rem;
            margin: 2vh 0 0;
        }

        &__text--color{
            color: $color-secondary;
        }

        &__text-span{
            font-weight: normal;
        }

        &__text-data{
            font-weight: bold;
        }

        &__status--booked{
            color: $color-secondary;
            font-weight: bold;
        }

        &__status--available{
            color: $color-alert;
            font-weight: bold; 
        }

        &__list--litters{
            padding: 0 2vw;
        }

        &__item-litter{
            list-style-type: none;
        }

        &__list-item{
            list-style: none;
        }
    
        &__list-item:before{
            content: "> ";
            font-weight: bold;
            color: $color-primary
        }

        &__our-kittens{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;

            @include mediaQ(large){
                grid-template-columns: 3fr 1fr;
            }
            
        }

        &__our2-kittens{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;

            @include mediaQ(large){
                grid-template-columns: 1fr 3fr;
            }
            
        }

        &__kitten-img-container{
            display: grid;
            align-content: center;
            justify-content: center;
        }

        &__parrents-wrapper{
            padding: 2vh 4vw;
        }

        &__img {
            margin-right: 1vw;
            -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            max-width: 90%;
            margin: 0 auto;
        }

        &__picture{
            max-width: 90%;
            margin: 0 auto;
            display: grid;
        }

        &__parrents-wrapper{
            padding: 2vh 4vw;
            border-bottom: 2px solid $color-secondary;
            display: grid;
            justify-content: space-around;
            grid-template-areas: "titleA titleA"
                                "titleB titleB"
                                "mother mother"
                                "father father";

            @include mediaQ(small){
            grid-template-areas: "titleA titleA"
                                "titleB titleB"
                                "mother father";
            }   
        }

        &__parentsA{
            grid-area: titleA;
            text-align: center;
        }

        &__parentsB{
            grid-area: titleB;
            text-align: center;
        }

        &__mother-wrapper{
            grid-area: mother;
            display: grid;
            justify-content: center;
        }

        &__mother{
            text-align: center;
        }

        &__father-wrapper{
            grid-area: father;
            display: grid;
            justify-content: center;
        }

        &__father{
            text-align: center;
        }
        
        &__link-wrapper{
            text-align: center;
            margin-top: 3vh;
            margin-bottom: 0.5vh;
        }

        &__mother-img{
            max-width: 300px;
            align-self: center;
        }

        &__father-img{
            max-width: 300px;
            align-self: center;
        }

        &__link{
            z-index: 20;
            font-size: 1.5rem;
            font-weight: bold;
            text-align: center;
            justify-self: center;
            align-self: center;
            color: $color-background-main;
            background-color: $color-secondary;
            background: linear-gradient(90deg, $color-gradient4 0%, $color-gradient5 50%, $color-gradient6 100%);;
            border: 1px solid $color-secondary;
            width:fit-content;
            cursor: pointer;
            padding: 2px 5px;
            border-radius: 5px;
            text-transform: uppercase;
            transition: 0.3s;
      
        &:hover{
          color: $color-secondary;
          background-color: $color-background-main;
          background: linear-gradient(90deg, rgba(100,185,238,0) 0%, rgba(80,165,218,0) 50%, rgba(60,145,198,0) 100%);
        }
        }

    }