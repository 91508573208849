@import 'colors.scss';

.home{
  z-index: 15;
  max-width: 1400px;
  margin: 1vh auto 5vh auto;

  &__title{
    margin: 3vh auto 5vh;
    font-size: 2rem;
      width: 50%;
      text-align: center;
      border-bottom: 2px solid $color-primary;
    }

    &__img-container{
      display: grid;
      justify-items: center;
      align-items: center;
    }

    &__img {
      -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
      -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
      box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      max-width: 100%;
      margin: 0 auto;
  }

  &__picture{
      max-width: 100%;
      margin: 0 auto;
      display: grid;
  }
  }
