$color-background-main: #FEFEFE;
$color-text-main: #020202;
$color-primary: darken(#50A5DA, 12%);
$color-secondary: #4A61C8;
$color-alert: #AF1C0E;
$color-info: #f5f512f3;
$color-gradient1: darken(rgba(100,185,238,1), 12%);
$color-gradient2: darken(rgba(80,165,218,1), 12%);
$color-gradient3: darken(rgba(60,145,198,1), 12%);
$color-gradient4: rgba(94,117,220,1);
$color-gradient5: rgba(74,97,200,1);
$color-gradient6: rgba(54,77,180,1);
