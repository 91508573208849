@import 'colors.scss';
@import "variables";

.males{
    z-index: 15;
    max-width: 1200px;
    margin: 1vh 3vw 5vh 3vw;

    @include mediaQ(small){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(medium){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(large){
        margin: 1vh auto 5vh auto;
    }

    &__title{
      margin: 3vh auto 5vh;
      font-size: 1.6rem;
      width: 50%;
      text-align: center;
      border-bottom: 2px solid $color-primary;

      @include mediaQ(large){
        font-size: 1.8rem;
      }

      @include mediaQ(large){
        font-size: 2rem;
      }
      }

      &__wrapper{
          display: grid;
          justify-items: center;
      }

      &__list{
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 2vh;
        margin: 0;
        padding: 0;

        @include mediaQ(large){
          grid-template-columns: 1fr; 
          grid-template-rows: 1fr;
          grid-gap: 2vw;
        }
    }

      &__text, &__list-item{
        font-size: 1rem;
        @include mediaQ(large){
            font-size: 1.4rem;
        }
    }

      &__img {
        -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        max-width: 90%;
        margin: 0 auto;
    }

    &__picture{
        max-width: 90%;
        margin: 0 auto;
        display: grid;
    }
}

