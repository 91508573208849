@import "colors.scss";
@import "variables";

.cat-info{
    height: auto;
    width: 100vw;
      max-width: 1200px;
      margin: 1vh auto 5vh auto;

    @include mediaQ(small){
        margin: 1vh auto 5vh auto;
    }

    @include mediaQ(medium){
      width: 80vw;
        margin: 1vh auto 5vh auto;
    }

    @include mediaQ(large){
        margin: 1vh auto 5vh auto;
    }
    
    &__title{
    margin: 3vh auto 5vh;
    font-size: 2rem;
      width: 50%;
      text-align: center;
      border-bottom: 2px solid $color-primary;
      
    }

    &__data-title{
      text-align: center;
      margin: 4vh auto;
      text-decoration: underline;
      font-size: 1.2rem;
        text-decoration-color: $color-primary;
        grid-area: subtitle;

        @include mediaQ(small){
          font-size: 1.4rem;
        }
  
        @include mediaQ(large){
          font-size: 1.6rem;
        }
    }
    
    &__wrapper{
      margin: 5vh 0;
      width: 80%;
      margin: 1vh auto;
      display: grid;
      justify-content: space-around;
      grid-gap: 2vw;
      align-items: center;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas: "photo"
                            "subtitle"
                            "info";
      @include mediaQ(xlarge){
        grid-template-areas: "subtitle subtitle"
                            "info photo"
                            "info photo";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      }
    }

    &__info{
      justify-self: center;
      grid-area: info;
    }

    &__text{
      font-weight: bold;
      text-align: justify;
      font-size: 1rem;

      @include mediaQ(small){
        font-size: 1.2rem;
      }

      @include mediaQ(large){
        font-size: 1.4rem;
      }

    &-main{
      font-weight: bold;
      text-decoration: underline;
      text-decoration-color: $color-primary;
      font-size: 1rem;

      @include mediaQ(small){
        font-size: 1.2rem;
      }

      @include mediaQ(large){
        font-size: 1.4rem;
      }
    }

    &-span{
      font-weight: normal;
    }
  }

    &__img-container{
      justify-self: center;
      display: grid;
      grid-area: photo;

      &--big{
        width: 100%;
      }
    }
    
    &__img {
      -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
      -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
      box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      max-width: 100%;
      margin: 0 auto;
      justify-self: center;
  }

  &__picture{
      max-width: 90%;
      margin: 0 auto;
      display: grid;
  }

    &__galery-title{
        text-align: center;
        text-decoration: underline;
        text-decoration-color: $color-primary;
        font-size: 1.2rem;

        @include mediaQ(small){
          font-size: 1.4rem;
        }
  
        @include mediaQ(large){
          font-size: 1.6rem;
        }
    }

    &__galery-wrapper{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        grid-gap: 2vw;

        @include mediaQ(small){
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          justify-content: space-between;
          align-items: center;
          justify-items: center;
        }

        @include mediaQ(large){
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr;
          justify-content: space-between;
          align-items: center;
          justify-items: center;
        }
    }

    &__button-container{
        display: grid;
        justify-items: center;
        margin-top: 2vh;
    } 
    
    &__button{
      margin-top: 1vh;
      margin-bottom: 1vh;
      z-index: 20;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      justify-self: center;
      align-self: center;
      color: $color-background-main;
      background-color: $color-primary;
      background: linear-gradient(90deg, $color-gradient1 0%, $color-gradient2 50%, $color-gradient3 100%);
      border: 1px solid $color-primary;
      width:fit-content;
      cursor: pointer;
      padding: 2px 5px;
      border-radius: 5px;
      text-transform: uppercase;
      transition: 0.3s;

  &:hover{
    color: $color-primary;
    background-color: $color-background-main;
    background: linear-gradient(90deg, rgba(100,185,238,0) 0%, rgba(80,165,218,0) 50%, rgba(60,145,198,0) 100%);
    }
}
}