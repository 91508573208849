@import "colors";
@import "variables";

    .footer{
        background: $color-background-main;
        box-sizing: border-box;
        width: 100vw;
        max-width: 1200px;
        margin: 0 auto;
        text-align: left;
        font: bold 14px sans-serif;
        padding: 55px 50px;  
        z-index: 25;
        

        @include mediaQ(xlarge){
            font: bold 16px sans-serif;
            } 

        &__main-wrapper{
            display: grid;
            grid-template-areas: "left left"
                                "center center"
                                "bottom bottom"
                                "route route";
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto auto auto;
            grid-gap: 2vw;

                @include mediaQ(small){
                    grid-template-areas: "left center"
					                    "bottom bottom"
                                        "route route";
		            grid-template-columns: 2fr 2fr;
		            grid-template-rows: auto 1fr auto;
		            grid-gap: 2vw;
		            align-items: center;
		            justify-content: center;
                    }

                    @include mediaQ(large){
                        grid-template-areas: "left center right"
                                            "bottom bottom bottom"
                                            "route route route";
                        grid-template-columns: 2fr 2fr 2fr;
                        grid-template-rows: auto 1fr auto;
                        grid-gap: 2vw;
                        align-items: center;
                        justify-content: center;
                        }
            }
        
    &__borderTop{
        width: 80%;
        margin: 0 auto 3vh auto;
        border-top: 2px solid $color-primary;
    }
    
    &__left,
    &__center,
    &__right{
        display: block;
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
        z-index: 25;

        @include mediaQ(small){
            margin-bottom: 40px;  
            } 

        @include mediaQ(xlarge){
            display: inline-block;
            vertical-align: top;   
            }    
    }
    
    &__left{
        padding: 0.5vh 0;
        grid-area: left;
    }
    
    &__logo-wrapper{
        width: 90%;
        margin: 0 auto;
        z-index: 25;
    }
    
    &__logo{
        width: 90%;
        z-index: 25;
    }
    
    &__links{
        width: 100%;
        color: $color-text-main;
        margin: 0 0 30px 0;
        padding: 0;
        z-index: 25;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto;
        font-size: 1.1rem;

        @include mediaQ(small){
            margin: 20px 0 12px;
            display: block;
            font-size: 0.9rem;
        }

        @include mediaQ(large){
            font-size: 1rem;
        }
    }
    
    &__links a{
        display:inline-block;
        line-height: 1.4;
        font-weight:400;
        text-decoration: none;
        z-index: 25;
        justify-self: center;
        align-self: center;

        @include mediaQ(small){
            line-height: 1.8;
        }
    }
    
    &__company-name{
        color:  $color-text-main;
        font-size: 0.8rem;
        font-weight: normal;
        margin: 0;
        z-index: 25;
        line-height: 1.8;
    }
    
    &__center{
        padding: 0.5vh 0;
        z-index: 25;
        grid-area: center;
        display: grid;

        @include mediaQ(small){
            display: block;
        }
    }

    &__data-container{
        display: grid;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
    }

    &__contact-data{
        justify-self: flex-start;
        cursor: pointer;

        &:hover .footer__center-icon{
            color: darken($color-primary, 15%);
        }
    }
    
    &__center-icon{
        background-color:  $color-background-main;
        color: $color-primary;
        font-size: 25px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin: 10px 15px;
        margin-left: 0;
        vertical-align: middle;
        z-index: 25;

        @include mediaQ(xlarge){
            margin-left: 15px;
            } 
    }
    
    &__center i.fa-envelope{
        font-size: 17px;
        line-height: 38px;
        z-index: 25;
    }
    
    &__center p{
        display: inline-block;
        color: $color-text-main;
        font-weight:400;
        vertical-align: middle;
        margin:0;
        z-index: 25;
    }
    
    &__center p span{
        display:block;
        font-weight: normal;
        font-size:14px;
        line-height:2;
        z-index: 25;
    }
    
    &__center p a{
        color:  $color-text-main;
        text-decoration: none;;
        z-index: 25;
    }
    
    &__links-before {
      font-weight:300;
      font-size: 20px;
      left: 0;
      color: $color-primary;
      display: inline-block;
      padding-right: 5px;
      z-index: 25;
      display: none;

      @include mediaQ(small){
          display: inline-block;
      }
    }

    &__link:hover{
        color: $color-primary;
        z-index: 25;
    }

    &__text, &__center>.footer__text{
        font-weight: bold;
        color: $color-primary;
        margin: 20px 0 12px;
        font-size: 1.4rem;

        @include mediaQ(small){
            font-size: 1rem;
        }

        @include mediaQ(medium){
            font-size: 1.1rem;
        }

        @include mediaQ(large){
            font-size: 1.2rem;
        }
    }
    
    &__right{
        padding: 0.5vh 0;
        display: none;

        @include mediaQ(large){
            display: block;
        }
    }
    
    &__img {
        width: 100%;
        max-width: 300px;
        margin-right: 1vw;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

        @include mediaQ(large){
            width: 300px;
            height: 300px;
        }
    }
    
    &__bottom{
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        padding: 0.5vh 2vw;
        z-index: 25;
        grid-area: bottom;

        @include mediaQ(large){
            grid-template-columns: 4fr 2fr;
            padding: 0.5vh 2vw;
        }
    
    }
    
    &__bottom-left{
    width: 100%;
    z-index: 25;
    align-items: center;
    justify-items: center;
    display: grid;
    grid-template-rows: auto auto;

    @include mediaQ(small){
        width: 100%;
        justify-items: center;
        grid-template-columns: 1fr auto;
        margin: 0 auto;
        grid-template-rows: auto;
    }

    @include mediaQ(medium){
        width: 80%;
        justify-items: center;
    }

    @include mediaQ(large){
        width: 100%;
        justify-items: flex-end;
        grid-gap: 1vw;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        padding-left: 0;
    }

    }

    &__active{
        color: $color-secondary;

        &:hover{
            color: $color-primary;
        }
    }
    
    &__bottom-right{
    display: grid;
    align-items: center;
    justify-items: center;
    z-index: 25;

        @include mediaQ(large){
            justify-items: flex-end;
        }

    }

    &__route {
        width: 100%;
        grid-area: route;
        text-align: center;
        p{
            color: $color-text-main;
            font-size: 0.8rem;
        }

        span{
            color: $color-secondary;
        }
    }
    }