@import "colors.scss";
@import "variables";

input, textarea{
	width: 90%;
	padding: 1vh 5%;
	margin: 1vh 5% 3vh;
	border: none;
	border-bottom: 1px solid $color-text-main;
	border-radius: 0px;
  }
  
  input:hover, textarea:hover{
	outline: none;
    border-bottom:1px solid $color-primary;
  }
  
  input:focus, textarea:focus{
	outline: none;
    border-bottom:1px solid $color-primary;
  }
  
  input:focus:hover, textarea:focus:hover{
	outline: none;
    border-bottom:1px solid $color-secondary;
  }
  
  label{
    font-size: 1.4rem;
  width: 90%;
	padding: 1vh 0;
	margin: 0 5%;
	border: none;
  }
  
  i:hover{
  color: $color-secondary;
  }
  
  a{
  color: black;
  text-decoration: none;
  cursor: pointer;
  }
  
  #contact__form{
      z-index: 1;
    grid-area: form;
    width: 90%;
    display: grid;
    margin: 0 5%;
    }

.contact{
  z-index: 1;
  max-width: 1200px;
  margin: 1vh 5vw 5vh 5vw;

    @include mediaQ(small){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(medium){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(large){
        margin: 1vh auto 5vh auto;
    }
    
  &__title{
      margin: 3vh auto 7vh;
      width: 50%;
      text-align: center;
      border-bottom: 2px solid $color-primary;
      font-size: 2rem;
    }

  &__text{
    font-size: 1rem;
            @include mediaQ(large){
                font-size: 1.4rem;
            }
    text-align: center;
    text-decoration: underline;
    text-decoration-color: $color-primary;

    &--subtitle{
      grid-area: msg;
      font-size: 1.4rem;
            @include mediaQ(large){
                font-size: 1.6rem;
            }
    }
  }

  &__img-right{
    grid-area: img1;
    }
    
    &__img-left{
        grid-area: img2;
        }

    &__data{
        grid-area: data;
        }
  
&__wrapper{
    z-index: 1;
	display: grid;
    grid-gap: 4vh;
	grid-template-areas:"data"
                      "img1"
                      "msg"
                      "form";

  @include mediaQ(large){
    grid-template-areas:"img1 data data"
                        "msg msg msg"
                        "form form img2";
  }

  }
  
  &__data{
    align-self: center;
    justify-self: center;
    z-index: 1;
    width: 90%;
    margin: 0 5%;
  }
  
  &__data-item{
    font-size: 1.4rem;
    display: grid;
    grid-template-columns: 1fr 8fr;
    cursor: pointer;

    &:hover .contact__data-icon{
      color: darken($color-primary, 15%);
    }
  }

  &__img {
    margin-right: 1vw;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
    -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
    box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    margin: 0 auto;
}

&__picture{
  max-width: 90%;
  margin: 0 auto;
  display: grid;
}

&__img-container-right{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh 0;
    margin: 0 auto;
}

&__img-right{
width: 70%;
margin: 0 auto;
}

&__img-container-left{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh 0;
}

&__img-left{
    width: 70%;
    display: none;
    

    @include mediaQ(large){
      display: block;
    }
    }
  
  &__data-icon{
  justify-self: center;
  align-self: center;
  color: $color-primary;
  }

  &__data-text{
    font-size: 1rem;
            @include mediaQ(large){
                font-size: 1.4rem;
            }
          }
  
  &__recaptcha>div{
    margin: 1vh 0;
    display: grid;
    justify-content: center;
  }

  &__success{
    text-align: center;
    color: green;
    font-size: 2rem;
  }

  &__unsuccess{
    text-align: center;
    color: $color-alert;
    font-size: 2rem;
  }
  
  &__btn{
      margin-top: 1vh;
      margin-bottom: 1vh;
      z-index: 20;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      justify-self: center;
      align-self: center;
      color: $color-background-main;
      background-color: $color-primary;
      background: linear-gradient(90deg, $color-gradient1 0%, $color-gradient2 50%, $color-gradient3 100%);
      border: 1px solid $color-primary;
      width:fit-content;
      cursor: pointer;
      padding: 2px 5px;
      border-radius: 5px;
      text-transform: uppercase;
      transition: 0.3s;

  &:hover{
    color: $color-primary;
    background-color: $color-background-main;
    background: linear-gradient(90deg, rgba(100,185,238,0) 0%, rgba(80,165,218,0) 50%, rgba(60,145,198,0) 100%);
  }

  &:disabled{
    color: grey;
    background-color: white;
    background: linear-gradient(90deg, rgba(100,185,238,0) 0%, rgba(80,165,218,0) 50%, rgba(60,145,198,0) 100%);
    border: 1px solid black;
    cursor: default;

    &:hover{
      color: grey;
    background-color: white;
    background: linear-gradient(90deg, rgba(100,185,238,0) 0%, rgba(80,165,218,0) 50%, rgba(60,145,198,0) 100%);
    border: 1px solid black;
    cursor: default;
    }
  }
}
} 