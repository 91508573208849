@import 'colors.scss';
@import "variables";

.litters{
    z-index: 10;
    max-width: 1200px;
    margin: 1vh 5vw 5vh 5vw;

    @include mediaQ(small){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(medium){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(large){
        margin: 1vh auto 5vh auto;
    }

    &__title{
        margin: 3vh auto 5vh;
          width: 50%;
          font-size: 2rem;
          text-align: center;
          border-bottom: 2px solid $color-primary;
        }
        
        &__section{
            padding: 2vh 2vw;
            margin-top: 0;
        }

        &__wrapper{
            list-style-type: none;
            padding: 0;
        }

        &__parrents-wrapper{
            margin: 1vh 5vw;
            display: grid;
            border-bottom: 2px solid $color-secondary;
        }

        &__item-litter{
            display: grid;
            list-style-type: none;
        }

        &__important{
            text-decoration: underline;
            text-decoration-color: $color-primary;
        }

        &__text, &__list-item{
            font-size: 1rem;

            @include mediaQ(small){
                font-size: 1.2rem;
            }
            @include mediaQ(large){
                font-size: 1.4rem;
            }
            
        }

        &__text--title{
            font-size: 1.2rem;
            text-align: center;

            @include mediaQ(small){
                font-size: 1.5rem;
            }
            @include mediaQ(large){
                font-size: 1.8rem;
            }
        }

        &__text--middle{
            text-align: center;
        }

        &__text--big {
            font-size: 3rem;
            margin: 2vh 0 0;
        }

        &__text--color{
            color: $color-secondary;
        }

        &__text-span{
            font-weight: normal;
        }

        &__text-data{
            font-weight: bold;
        }

        &__status--booked{
            color: $color-secondary;
            font-weight: bold;
        }

        &__status--available{
            color: $color-alert;
            font-weight: bold; 
        }

        &__list--litters{
            padding: 0 2vw;
        }

        &__list-item{
            list-style: none;
        }
    
        &__list-item:before{
            content: "> ";
            font-weight: bold;
            color: $color-primary
        }

        &__our-kittens{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;

            @include mediaQ(large){
                grid-template-columns: 3fr 1fr;
            }
            
        }

        &__kitten-img-container{
            display: grid;
            align-content: center;
            justify-content: center;
        }

        &__parrents-wrapper{
            padding: 2vh 4vw;
            border-bottom: 2px solid $color-secondary;
            display: grid;
            justify-content: space-around;
            grid-template-areas: "title title"
                                "mother mother"
                                "father father";

            @include mediaQ(large){
            grid-template-areas: "title title"
                                "mother father";
            }   
        }

        &__parents{
            grid-area: title;
            text-align: center;
        }

        &__mother-wrapper{
            grid-area: mother;
            display: grid;
            justify-content: center;
        }

        &__mother{
            text-align: center;
        }

        &__father-wrapper{
            grid-area: father;
            display: grid;
            justify-content: center;
        }

        &__father{
            text-align: center;
        }

        &__img {
            margin-right: 1vw;
            -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            max-width: 90%;
            margin: 0 auto;
            

            &--link{
                margin-right: 1vw;
                -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
                -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
                box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                max-width: 90%;
                margin: 0 auto;
                transition: 0.2s;
            }
           &--link:hover{
            opacity: 0.8;
           }
        }

        &__picture{
            max-width: 90%;
            margin: 0 auto;
            display: grid;
        }

        &__mother-img{
            max-width: 300px;
            align-self: center;
        }

        &__father-img{
            max-width: 300px;
            align-self: center;
        }
        
        &__button-container{
            text-align: center;
            margin-top: 1vh;
            margin-bottom: 1vh;
        }

    }