@import "colors.scss";
@import "variables";

.carousel-root{
    display: grid;
}

.carousel-slider{
    height: 60vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 4fr auto;
    grid-gap: 0vh;
    grid-template-areas: "photo"
                        "dots";

                        @include mediaQ(small){
                            grid-template-areas: "photo"
                            "photo";
                        }
}


.thumbs-wrapper{
    display: none;

    @include mediaQ(small){
    display: grid;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    }
}

.thumbs{
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 0;
    width: 100%;
}

.carousel .thumb{
    justify-self: center;
    align-self: center;
    margin: 0 auto;
    display: grid;
}

.slider{
    display: grid;
    align-content: center;
}

.slide{
    display: grid;
    justify-content: center;
    align-content: center;
    grid-gap: 1vh;
}

.slide .selected{
    vertical-align:baseline;

}

.slider-wrapper{
    display: grid;
    height: 100% !important;
    justify-content: center;
    grid-area: photo;

    @include mediaQ(small){
        height: 100% !important;
    }
}

.carousel .thumb.selected, .carousel .thumb:hover{
    border: 2px solid $color-primary;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.carousel .thumb:hover{
    border: 2px solid $color-secondary;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.carousel .control-dots{
    grid-area: dots;
    position: unset;
    height: 30px;

    @include mediaQ(small){
        display: none;
    }
}

.carousel .control-dots .dot{
    background-color: $color-primary;
    width: 20px;
    height: 20px;

    @include mediaQ(small){
        display: none;
    }
}