@import 'colors.scss';
@import "variables";

.ragdoll{
    z-index: 10;
    max-width: 1200px;
    margin: 1vh 5vw 5vh 5vw;

    @include mediaQ(small){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(medium){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(large){
        margin: 1vh auto 5vh auto;
    }

    &__section{
        padding: 2vh 2vw;
    }

    &__title{
        margin: 3vh auto 5vh;
        font-size: 2rem;
        width: 50%;
        text-align: center;
        border-bottom: 2px solid $color-primary;
        }

        &__important--title{
            text-decoration: underline;
            text-decoration-color: $color-primary;
        }

        &__text, &__list-item{
            font-size: 1rem;
            text-align: justify;
            @include mediaQ(large){
                font-size: 1.4rem;
            }
        }

        &__important--title{
            text-decoration: underline;
            text-decoration-color: $color-primary;
            font-size: 1.2rem;
            @include mediaQ(large){
                font-size: 1.5rem;
            }
        }

        &__list-item{
            list-style: none;
        }
    
        &__list-item:before{
            content: "> ";
            font-weight: bold;
            color: $color-primary
        }

        &__cat-img-container{
            display: grid;
            align-content: center;
            justify-content: center;
        }

        &__img {
            -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            max-width: 90%;
            margin: 0 auto;
        }
    
        &__picture{
            max-width: 90%;
            margin: 0 auto;
            display: grid;
        }
    }