@import 'colors.scss';
@import "variables";

.about-us{
    z-index: 10;
    max-width: 1200px;
    margin: 1vh 5vw 5vh 5vw;

    @include mediaQ(small){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(medium){
        margin: 1vh 3vw 5vh 3vw;
    }

    @include mediaQ(large){
        margin: 1vh auto 5vh auto;
    }

    &__title{
        margin: 3vh auto 5vh;
        font-size: 2rem;
          width: 50%;
          text-align: center;
          border-bottom: 2px solid $color-primary;
        }

    &__data-container-text{
        padding: 2vh 2vw;
        display: grid;
        grid-gap: 2vw;
        grid-template-areas: "title title"
                                "text1 text1"
                                "text2 text2"
                                "photo photo"
                                "text3 text3"
                                "text4 text4";
        @include mediaQ(large){
            grid-template-areas: "title title"
            "text1 photo"
            "text2 photo"
            "text3 photo"
            "text4 text4";
        }
            
    }

    &__certificate-wrapper, &__cats-wrapper{
        display: grid;
        margin: 2vh 0;
        padding: 2vh 2vw;
    }

    &__certificate-container{
        display: grid;
        margin: 1vh 0;
    }
    &__certificate{
        justify-self: center;
    }

    &__text{
        &--title{
            grid-area: title;
        }
        &--one{
            grid-area: text1;
        }
        &--two{
            grid-area: text2;
        }
        &--three{
            grid-area: text3;
        }
        &--four{
            grid-area: text4;
        }

        &--center{
            text-align: center !important;
        }
    }

    &__text, &__list-item{
        font-size: 1rem;
        text-align: justify;
            @include mediaQ(large){
                font-size: 1.4rem;
            }
    }

    &__important{
        text-decoration: underline;
        text-decoration-color: $color-primary;
    }

    &__link{
        z-index: 20;
        cursor: pointer;
        color: $color-secondary;
    }

    &__link:before{
        content: " ";
    }

    &__list-item{
        list-style: none;
    }

    &__list-item:before{
        content: "> ";
        font-weight: bold;
        color: $color-primary
    }

    &__img {
        -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        max-width: 90%;
        margin: 0 auto;
    }

    &__picture{
        max-width: 90%;
        margin: 0 auto;
        display: grid;
    }

    &__owner-container {
        display: grid;
        align-content: center;
        justify-self: center;
        grid-area: photo;
    }



    &__cat-img-container{
        display: grid;
        align-content: center;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
    }

}