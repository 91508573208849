@import "colors";
@import "variables";

.menu{
    width: 100vw;
    height: $menu-height;
    overflow: hidden;
    background-color: $color-primary;
    background: linear-gradient(90deg, $color-gradient1 0%, $color-gradient2 50%, $color-gradient3 100%);
    z-index: 26;
    position: fixed;
    top: 0;
    display: grid;
    justify-content: center;
    align-content: center;
    transition: 0.3s;
    
    
    &__wrapper{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto;
    justify-content: center;
    align-content: center;
    max-width: 1200px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    z-index: 26;
    transition: 0.3s;
    }
    
    &__logo-wrapper{
    display: flex;
    justify-content: center;
    height: $menu-height;
    z-index: 26;
    transition: 0.3s;
    margin-left: 5vw;
    }
    
    &__logo{
      transition: 0.3s;
      z-index: 26;
      height: 70%;
      margin: auto 0;

      @include mediaQ(small){
        height: 90%;
      }
    
    }
    
    &__nav{
        display:none;
         height: 100%;
         padding-left: 5vw;
         z-index: 26;

          @include mediaQ(xlarge){
            display: block;
            width: 55vw;
            }

          @include mediaQ(xxlarge){
            width: 45vw;
            }
    }

    &__nav-wrapper{
    display: block;
    height: 100%;
    z-index: 26;
    }
       
    &__nav-content{
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: space-evenly;
    align-content: space-evenly;
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 26;
    }

    &__nav-link{
        text-decoration: none;
        color: $color-background-main;

        &--red{
        color: $color-alert;
      }

      &--yellow{
        color: $color-info;
      }

        }
        
        &__nav-item{
        font-size: 1.4rem;
        transition: 0.3s;
        border-bottom: 4px solid transparent;

        
        }
        
        &__nav-item:hover{
          border-bottom-color: $color-primary;
          @include mediaQ(xlarge){
            border-bottom-color: $color-background-main;
            }         
        } 
    }
    
.burger-nav{

    &__nav-wrapper{
        width: 100%;
        height: 90vh;
        overflow: hidden;
        visibility: hidden;
        position: fixed;
        top: 20vh;
        margin-top: 0;
        z-index: 0;
        }
       
    &__background-wrapper{
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 90vh ;
      z-index: 0;
    }

    &__nav-content{
    visibility: hidden;
    opacity:0;
    display: grid;
    grid-gap: 5px;
    align-content: space-evenly;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 15vh 0 10vh 0;
    width: 100%;
    height: 50vh;
    color: $color-background-main;
    position: absolute;
    z-index: 0;
    }
    
    
    
    &__background-primary{
      visibility: hidden;
      background-color: $color-primary;
      width: 100%;
      height: 100vh;
      z-index: 28;
      position: absolute;
    }
    
    &__background-secondary{
      visibility: hidden;
      background-color: $color-background-main;
      width: 100%;
      height: 100vh;
      z-index: 29;
      position: absolute;
    }
    

    
    &__nav-item{
    text-align: center;
    width: 100%;
    font-size: 2rem;
    transition: 0.3s;

    @include mediaQ(large){
      font-size: 3rem;
    }
    }

    &__nav-link{
      border-bottom: 3px solid transparent;
      transition: 0.3s;
    &:hover{
        border-bottom-color: $color-primary;
          @include mediaQ(xlarge){
            border-bottom-color: $color-background-main;
            } 
            
          }
    }
    
    &__nav{
    display: block;
    width: 80vw;
      height: $menu-height;
      padding-left: 10vw;
    }
   
}

.burger{
    display: grid;
    width: 60vw;
    height: $menu-height;
    padding-left: 0;
    margin-right: 5vw;
    //cursor: pointer;
    //z-index: 51;

  @include mediaQ(xlarge){
    display: none;
    }
    
    &__icon-wrapper{
        display: grid;
        //z-index: 51;
      }
    
    &__container{
    justify-self: end;
    align-self: center;
    position: relative;
    width: $menu-height /2;
    height: $menu-height /2; 
    z-index: 51;
    }

    &__item1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5vh;
    height: 0.5vh;
    background-color: $color-background-main;
    border-radius: 2%;
    z-index: 51;
    }

    &__item4{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -300%);
    width: 5vh;
    height: 0.5vh;
    background-color: $color-background-main;
    border-radius: 2%;
    z-index: 51;
    }
    
    &__item3{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 200%);
    width: 5vh;
    height: 0.5vh;
    background-color: $color-background-main;
    border-radius: 2%;
    z-index: 51;
    }
    
    &__item2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5vh;
    height: 0.5vh;
    background-color: $color-background-main;
    border-radius: 2%;
    z-index: 51;
    }
}

.home_active{
  border-bottom: 3px solid $color-primary;
  @include mediaQ(xlarge){
    border-bottom: 3px solid $color-background-main;
    }
}

.news_active{
  border-bottom: 3px solid $color-primary;
  @include mediaQ(xlarge){
    border-bottom: 3px solid $color-background-main;
    }
}

.about_active{
  border-bottom: 3px solid $color-primary;
  @include mediaQ(xlarge){
    border-bottom: 3px solid $color-background-main;
    }
}

.ragdoll_active{
  border-bottom: 3px solid $color-primary;
  @include mediaQ(xlarge){
    border-bottom: 3px solid $color-background-main;
    }
}

.females_active{
  border-bottom: 3px solid $color-primary;
  @include mediaQ(xlarge){
    border-bottom: 3px solid $color-background-main;
    }
}

.males_active{
  border-bottom: 3px solid $color-primary;
  @include mediaQ(xlarge){
    border-bottom: 3px solid $color-background-main;
    }
}

.kittens_active{
  border-bottom: 3px solid $color-primary;
  @include mediaQ(xlarge){
    border-bottom: 3px solid $color-background-main;
    }
}

.contact_active{
  border-bottom: 3px solid $color-primary;
  @include mediaQ(xlarge){
    border-bottom: 3px solid $color-background-main;
    }
}