@import "colors";
@import "variables";

.scrollUpButton{
    position: fixed; 
    width: 10vw;
    height: 5vh;
    left: 85%;
    bottom: 5vh;
    font-size: 3rem;
    z-index: 100;
    cursor: pointer;
    color: $color-primary;
    opacity: 0.8;

    @include mediaQ(small){
        width: 8vw;
        height: 6vh;
        left: 90%;
        bottom: 5vh;
    }

    @include mediaQ(large){
        width: 5vw;
        height: 5vh;
        left: 92%;
        bottom: 5vh;
    }

    &:hover{
        opacity: 1;
    }
}