@import 'colors.scss';
@import "variables";

.cat{

    &__name{
        text-align: center;
    }

    &__wrapper{
        display: grid;
        grid-template-columns: 1fr auto;
    }

    &__item{
        z-index: 19;
        display: grid;
        grid-template-rows: auto auto auto;
        grid-gap: 2vh;
        align-content: center;
        align-items: center;
        padding-bottom: 2vh;
        border-bottom: 2px solid $color-primary;
        @include mediaQ(large){
            border-bottom: none;
            grid-template-rows: auto 3fr auto;
          }
    }

    &__item-litter{
        display: grid;
        grid-gap: 2vw;
        grid-template-areas: "name"
            "photo"
            "data";
            border-bottom: 2px solid $color-primary;
            padding-bottom: 4vh;
            margin-bottom: 4vh;
        @include mediaQ(small){
            grid-template-areas: "name name"
            "data photo";
        }
      }
    &__list{
        list-style-type: none;
    }
    &__title{
        margin: 3vh auto 5vh;
        width: 80%;
        text-align: center;
        font-size: 1.2rem;
        @include mediaQ(small){
            margin: 2vh auto 2vh;
            font-size: 1.4rem;
          }
    
          @include mediaQ(large){
            font-size: 1.6rem;
          }
    }

    &__title-litter{
        grid-area: name;
        justify-self: center;
        font-size: 1.2rem;
        @include mediaQ(small){
            font-size: 1.4rem;
            }
        @include mediaQ(large){
        font-size: 1.8rem;
        }
    }

    &__text, &__list-item{
        font-size: 1rem;
        @include mediaQ(large){
            font-size: 1.4rem;
        }
    }

    &__info-litter{
        grid-area: data;
        justify-self: center;
    }
    
    &__img-wrapper{
        display: grid;
    }

    &__img-wrapper-litter{
        grid-area: photo;
        justify-self: center;
        display: grid;
        justify-content: center;
        align-content: center;
        &--center{
            justify-self: center;
            display: grid;
            justify-content: center;
            align-content: center;
        }
    }

    &__img {
        -webkit-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        -moz-box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.14);
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        max-width: 90%;
        margin: 0 auto;
        transition: 0.3s;

    }

    &__picture{
        max-width: 90%;
        margin: 0 auto;
        display: grid;
    }

    &__link{
        font-weight: bold;
        margin-top: 1vh;
        margin-bottom: 1vh;
        z-index: 20;
        font-size: 1.5rem;
        text-align: center;
        justify-self: center;
        align-self: center;
        color: $color-background-main;
        background-color: $color-primary;
        background: linear-gradient(90deg, $color-gradient1 0%, $color-gradient2 50%, $color-gradient3 100%);
        border: 1px solid $color-primary;
        width:fit-content;
        cursor: pointer;
        padding: 2px 5px;
        border-radius: 5px;
        text-transform: uppercase;
        transition: 0.3s;
  
    &:hover{
      color: $color-primary;
      background-color: $color-background-main;
      background: linear-gradient(90deg, rgba(100,185,238,0) 0%, rgba(80,165,218,0) 50%, rgba(60,145,198,0) 100%);
    }
    }
}